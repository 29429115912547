import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class ServicesPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location="./services" title={siteTitle}>
        <SEO title="Our Services" keywords={[`painting`, `historical`]} />
        <ul>
          <li>Interior and Exterior painting</li>
          <li>Drywall and plaster repair</li>
          <li>Color consultation</li>
          <li>Complete historic restoration with documentation</li>
          <li>Wallpaper removal</li>
          <li>Custom stain matching with period finishing</li>
          <li>Gilding - gold, silver, copper</li>
          <li>Murals</li>
          <li>A repertoire of lost historic techniques and finishes</li>
          <li>
            Providing products and techniques for environmentally sensitive
            clients
          </li>
          <li>Light carpentry</li>
        </ul>
      </Layout>
    );
  }
}

export default ServicesPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
